import React from 'react'
import { Link } from 'gatsby'
import headerStyles from './header.module.scss'
class Header extends React.Component {

  state = { showMenu: false }

  toggleMenu = () => {
      this.setState({
          showMenu: !this.state.showMenu
      })
  }
 
  render () {
      
    const menuActive = this.state.showMenu ? headerStyles.navBarLinkToggleShow : headerStyles.navBarRight;
    
    return (
      <header className={headerStyles.header}>
        <div className={headerStyles.navBar}>
          <div className={headerStyles.navBarItem}>
            <Link className={headerStyles.title} to="/">ClassWarfare 101</Link>
          </div>
          <div role="button" onClick={this.toggleMenu} className={headerStyles.navBarLinkToggle}>
            <i class="fas fa-bars"></i>
          </div>
          <nav className={headerStyles.navBarLeft}>
          </nav>
          <nav className={menuActive}>
            <div className={headerStyles.navBarItem}><Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/blog">Blog</Link></div>
          </nav>
        </div>
      </header>
    )
  };
}

export default Header;

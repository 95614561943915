import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import footerStyles from './footer.module.scss'
import { FaFacebookSquare }  from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

class FbIco extends React.Component {
  render() {
    return <span><FaFacebookSquare /></span>
  }
}

class TwitterIco extends React.Component {
  render() {
    return <span><FaTwitter /></span>
  }
}


const Footer = () => {

  const data = useStaticQuery( graphql`
    {
      site {
        siteMetadata {
          author,
          authorEmail
        }
      }
    }
    `)

    return (
      <footer className={footerStyles.footer}>

        <h2 className={footerStyles.major}>Connect with us</h2>
        <span className={footerStyles.byline}>Join the resistance!</span>
        <ul className={footerStyles.icons}>
          <li><a href="https://www.facebook.com/ClassWarfare101-425785464863851/"><FbIco/></a></li>
          <li><a href="https://twitter.com/classwarfare101"><TwitterIco/></a></li>
          </ul>
          <div className={footerStyles.byline2}>Brought to you by <a href={"mailto:" + data.site.siteMetadata.authorEmail}>{data.site.siteMetadata.author}</a></div>
      </footer>
    )
}

export default Footer

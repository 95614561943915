import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

class AllPages extends Component {
  render() {
    const data = this.props.data

  return (
    <Layout>
          <h1>The page you navigated too does not exists</h1>
          <h3>You can choose from the pages or blog posts below.</h3>
          <p>&nbsp;</p>
          <h1>Class WarFare 101 Pages</h1>
          {data.allWordpressPage.edges.map(({ node }) => (
            <div key={node.slug}>
              <Link to={node.slug} css={{ textDecoration: `none` }}>
                <h3>{node.title}</h3>
              </Link>
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          ))}
        <hr />
        <h1>ClassWarFare 101 Posts</h1>
        {data.allWordpressPost.edges.map(({ node }) => (
          <div key={node.slug}>
            <Link to={'post/' + node.slug} css={{ textDecoration: `none` }}>
              <h3>{node.title}</h3>
            </Link>
            <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
          </div>
        ))}
    </Layout>
	)
  }
}

export default AllPages

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          excerpt
          slug
        }
      }
    }
  }
`
